@import "../abstracts/variables";
@import "../components/pay-items";
@import "../components/input-gradient";
@import "../mixins/mixins";

.giveaway-page {
  margin: 0 auto 40px auto;
  padding: 70px 0;
  max-width: 1170px;

  @media(max-width: 992px) {
    padding: 30px 0;
  }
}

input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"] {
  cursor: pointer;
}

.giveaway-content {
  display: flex;
  gap: 48px;

  @media(max-width: 1200px) {
    padding: 0 20px;
  }

  @media(max-width: 992px) {
    flex-direction: column;
    gap: 30px;
  }

  @media(max-width: 640px) {
    gap: 40px;
  }

  &-left,
  &-right {
    width: 50%;

    @media(max-width: 992px) {
      width: 100%;
    }
  }

  &-left {
    min-width: 600px;

    @media(max-width: 1280px) {
      min-width: auto;
    }
  }
}

.giveaway-form {
  position: relative;
  margin-bottom: 4px;

  @media(max-width: 992px) {
    width: 100%;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    color: rgba(230, 155, 255, 0.4);
    margin-bottom: 4px;
  }

  .input-gradient {
    margin-bottom: 50px;
    width: 100%;

    @include before() {
      background: #8c009c;
    }

    .icon {
      display: flex;
      padding: 0 5px;

      input {
        padding-left: 30px;
      }
    }
  }

  .giveaway-form-date {
    display: flex;
    gap: 24px;

    @media(max-width: 480px) {
      flex-direction: column;
      gap: 0;
    }

    .material-icons {
      font-size: 28px;
      background: #8847ff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .input-gradient {
      input {
        font-weight: normal;
        color: rgba(230, 155, 255, 0.8);
      }
    }
  }

  &-btns {
    @include flex(row);
    gap: 12px;

    .btn-violet {
      box-shadow: inset 0 0 0 4px #d32ce6, 0 0 16px 0 rgba(211, 44, 230, 0.64);
      background: rgba(211, 44, 230, 0.2);
      border: 0;
      color: #fff;
      flex-grow: 1;

      &:hover {
        box-shadow: inset 0 0 0 6px #d32ce6, 0 0 16px 0 rgba(211, 44, 230, 0.64);
        background: rgba(211, 44, 230, 0.48);
        text-shadow: 0 0 8px rgba(255, 255, 255, 0.64);
      }
    }

    .btn-replay {
      @include flex(row, center, center);
      width: 64px;
      height: 64px;
      text-decoration: none;
      color: #f7dbff;
      background: rgba(230, 155, 255, 0.08);
      box-shadow: inset 0 0 0 4px rgba(230, 155, 255, 0.2);
      border-radius: 50%;
      transition: all .3s;

      .icon {
        font-size: 32px;
      }

      &:hover {
        box-shadow: inset 0 0 0 6px rgba(230, 155, 255, 0.2);
      }

      .animate {
        animation: replayRotate 1s;
        -webkit-animation: replayRotate 1s;
      }

      @keyframes replayRotate {
        100% {
          transform: rotate(-360deg);
          -webkit-transform: rotate(-360deg);
        }
      }
    }
  }
}

.giveaway-winner {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 52px;

  &-wrap {
    max-width: 100%;

    @media(max-width: 640px) {
      max-width: calc(100% - 84px);
    }
  }

  @media(max-width: 992px) {
    width: 100%;
  }

  @media(max-width: 640px) {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 13px;
  }

  &-title {
    text-align: center;
    margin-bottom: 16px;
    font-weight: 900;
    font-size: 24px;
    text-align: center;

    &>:not(span):first-child {
      display: inline-block;
      transform: scale(-1, 1);
    }

    @media(max-width: 640px) {
      font-size: 16px;
      text-align: left;
      margin-bottom: 8px;
    }

    span {
      margin: 0 8px;
      color: rgba(230, 155, 255, 0.4);

      @media(max-width: 640px) {
        font-size: 20px;
      }
    }
  }

  .user-ava {
    width: 180px;
    height: 180px;
    margin-bottom: 27px;
    box-shadow: 0 0 240px 0 rgba(211, 44, 230, 0.64);
    border-radius: 50%;
    outline: 12px solid rgba(36, 0, 40, 0.4);

    @media(max-width: 640px) {
      width: 64px;
      min-width: 64px;
      height: 64px;
      margin-right: 20px;
      box-shadow: 0 0 48px 0 rgba(211, 44, 230, 0.32);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .user-name {
    font-weight: 900;
    font-size: 40px;
    line-height: 1;
    color: rgba(230, 155, 255, 0.8);
    text-align: center;
    white-space: nowrap;
    @include flex(row, center);

    &>span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 100%;
    }

    @media(max-width: 640px) {
      font-size: 20px;
    }

    @media(max-width: 359px) {
      flex-direction: column;
      align-items: flex-start;

      &>span {
        max-width: calc(100vw - 115px);
      }

      .user-name-num {
        margin-left: 0;
      }
    }

    &-num {
      margin-left: 16px;
      font-weight: 700;
      font-size: 24px;
      line-height: 1.66667;
      color: rgba(169, 122, 255, 0.8);
      @include flex(row, center);

      @media(max-width: 640px) {
        font-size: 16px;
      }

      .icon {
        font-size: 32px;
        padding: 0 8px;

        @media(max-width: 640px) {
          font-size: 24px;
        }
      }
    }
  }
}

.giveaway-table-items {
  max-height: 503px;
  overflow: auto;
  width: calc(100% - 2px);

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(214, 53, 187, 0.64);
    border-radius: 8px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
}

.giveaway-table-items-row {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 8px 0 8px 30px;
  background: radial-gradient(50% 8814.97% at 50% 50%, rgba(211, 44, 230, 0.04) 0%, rgba(211, 44, 230, 0) 100%);
  margin-bottom: 4px;
  cursor: pointer;

  &-num {
    color: #A97AFF;
    min-width: 33px;
    font-weight: 700;
    font-size: 16px;
  }

  .top-drop-item {
    box-shadow: none;
    margin: 0 20px 0 11px;
    width: 112px;
    height: 64px;
    min-width: 112px;

    .drop-img {
      height: 100%;
      z-index: 3;
      position: relative;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: cover;
      transition: all .3s;
    }

    .top-drop-item-name {
      margin: 6px 0 0 0;
    }

    .in-case-cost {
      top: 3px;
      right: 3px;
    }

    &:hover {
      .drop-img {
        margin-top: 0;
      }
    }
  }

  &:hover {
    background: radial-gradient(50% 8814.97% at 50% 50%, rgba(211, 44, 230, 0.08) 0%, rgba(211, 44, 230, 0) 100%);

    .top-drop-item {
      .top-drop-item-hover {
        margin-top: 0;
        opacity: 1;
      }

      .drop-img {
        transform: translateY(-10px);
      }

      .in-case-cost {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.giveaway-table-items-row-user {
  display: flex;
  align-items: center;
  flex: 1;
  max-width: calc(100% - 123px);

  &-ava {
    box-shadow: inset 0 0 0 2px #360153;
    transition: all .3s;
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 2px;
    margin-right: 12px;

    &:before {
      @extend %before-after;
      border: 2px solid #360153;
      transition: all .3s;
      pointer-events: none;
      width: 100%;
      height: 100%;
      z-index: 3;
      border-radius: 50%;
      box-sizing: border-box;
    }

    img {
      width: 100%;
      height: 100%;
      position: relative;
      border-radius: 50%;
      z-index: 2;
    }

    .tooltipe {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      padding: 2px 8px;
      background: linear-gradient(90deg, #380B9D 0%, #490475 100%);
      border-radius: 4px;
      font-size: 14px;
      line-height: 16px;
      color: #FFFFFF;
      white-space: nowrap;
      opacity: 0;
      transition: all .3s;
    }

    &:hover {
      &:before {
        border-width: 4px;
      }

      .tooltipe {
        opacity: 1;
        margin-top: -2px;
      }
    }
  }

  &-name {
    font-size: 16px;
    line-height: 19px;
    color: #CCCCCC;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.giveaway-table-items-row-ticket {
  @include flex(row, center);
  gap: 8px;
  font-size: 16px;

  .icon {
    color: #a97aff;
  }
}

.giveaway-table {
  padding: 24px;
  border-radius: 28px;
  background: rgba(255, 255, 255, 0.01);
  margin-top: -12px;

  @media(max-width: 640px) {
    background: none;
    padding: 0;
  }

  &-title {
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    text-align: center;
    color: rgba(213, 185, 255, 0.5);
    margin-bottom: 24px;
  }

  &-items {
    @media(max-width: 640px) {
      display: none;
    }

    &-row-parent {
      min-width: 92vw;
      width: 92vw;
    }

    &__mob {
      display: none;
      overflow: hidden;

      @media(max-width: 640px) {
        display: flex;
      }
    }
  }

  &.giveaway-table-empty {
    background: rgba(255, 255, 255, 0.01);
    border: 2px solid rgba(132, 76, 176, 0.16);
    border-radius: 28px;
    height: calc(100% + 12px);

    @media(max-width: 992px) {
      display: none;
    }

    .giveaway-table-title {
      display: none;
    }

    .giveaway-table-items {
      height: 100%;


      &.giveaway-table-items-empty {
        overflow: hidden;
        @include flex(row, center, center);
        font-weight: 700;
        font-size: 24px;
        line-height: 1.16667;
        color: rgba(213, 185, 255, 0.5);
      }

      &.giveaway-table-items__mob {
        display: none;
      }
    }
  }
}

.giveaway-table-items-controls {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 20px;
  display: none;

  @media(max-width: 640px) {
    display: flex;
  }

  &-line {
    border-radius: 5px;
    background: rgba(0, 0, 0, .3);
    height: 10px;
    flex-grow: 1;
    padding: 2px;

    &-progress {
      background: #d635bb;
      opacity: 0.64;
      height: 100%;
      border-radius: 4px;
    }
  }

  &-btns {
    @include flex(row, center);
    gap: 4px;

    &-left,
    &-right {
      background: rgba(230, 155, 255, 0.08);
      box-shadow: inset 0 0 0 2px rgba(230, 155, 255, 0.2);
      border-radius: 32px;
      width: 40px;
      height: 40px;
      transition: all .3s;
      @include flex(row, center, center);

      &:hover {
        box-shadow: inset 0 0 0 4px rgba(230, 155, 255, 0.2);
      }
    }
  }
}