@import '../abstracts/extends';

.input-gradient {
  position: relative;

  .FakeInput {
    input {
      position: relative;
      z-index: 2;
     // opacity: 0;
    }

    input:focus + .fake-label {
      top: -24px;
      left: 0;
      transform: translateY(0%);
      color: rgba(230, 155, 255, 0.4);
    }
  }

  .fake-input {
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #D32CE6;
    text-shadow: 0px 0px 24px #d32ce6;
    position: absolute;
    top: 1px;
    height: 32px;
    width: 100%;
    padding-left: 40px;
    outline: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow-x: clip;
    padding-right: 40px;

    & > * {
      color: inherit;
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      white-space: inherit !important;
      background-color: inherit !important;
    }

    &:focus {
      & ~ .fake-label {
        top: -24px;
        left: 0;
        transform: translateY(0%);
        color: rgba(230, 155, 255, 0.4);
      }
    }
  }

  .fake-label {
    line-height: 28px;
    color: rgba(230, 155, 255, 0.4);
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translateY(-50%);
    pointer-events: none;
    transition: all 0.5s;
  }

  .percent {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;
    text-align: right;
    color: #d32ce6;
    text-shadow: 0px 0px 24px #d32ce6;
  }

  .link-copy {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    font-size: 24px;
    line-height: 24px;
    color: rgba(230, 155, 255, 0.4);
    display: block;
    width: 24px;
    height: 24px;
    transition: all 0.5s;

    &:hover {
      color: #e69bff;
    }
  }

  input {
    padding-left: 40px;
    background: none;
    font-size: 18px;
    line-height: 28px;
    color: #d32ce6;
    border: 0;
    width: 100%;

    &::-webkit-input-placeholder {
      font-size: 16px;
      line-height: 19px;
      color: rgba(230, 155, 255, 0.4);
      font-weight: 400;
    }

    &::-moz-placeholder {
      font-size: 16px;
      line-height: 19px;
      color: rgba(230, 155, 255, 0.4);
      font-weight: 400;
    }

    &:-ms-input-placeholder {
      font-size: 16px;
      line-height: 19px;
      color: rgba(230, 155, 255, 0.4);
      font-weight: 400;
    }

    &:-moz-placeholder {
      font-size: 16px;
      line-height: 19px;
      color: rgba(230, 155, 255, 0.4);
      font-weight: 400;
    }

    &:focus {
      outline: none;
    }
  }

  .icon {
    max-width: 32px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    img,
    svg {
      width: 100%;
      height: auto;
    }
  }

  &:before {
    @extend %before-after;
    background: linear-gradient(90deg, #6D007A 0%, #370047 100%);
    width: 100%;
    height: 2px;
    top: 100%;
    margin-top: 4px;
  }

  &:hover {
    .fake-label {
      color: rgba(230, 155, 255, 0.8);
    }

    input {
      &::-webkit-input-placeholder {
        color: rgba(230, 155, 255, 0.8);
      }

      &::-moz-placeholder {
        color: rgba(230, 155, 255, 0.8);
      }

      &:-ms-input-placeholder {
        color: rgba(230, 155, 255, 0.8);
      }

      &:-moz-placeholder {
        color: rgba(230, 155, 255, 0.8);
      }
    }

    &:before {
      background: linear-gradient(90deg, #8C009C 0%, #500067 100%);
    }
  }

  &.active {
    .fake-label {
      top: -24px;
      left: 0;
      transform: translateY(0%);
      color: rgba(230, 155, 255, 0.4);
    }
  }

  .icon {
    &.copy,
    &.cancel,
    &.check {
      font-size: 24px;
      line-height: 24px;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      color: rgba(211, 44, 230, 0.4);
      position: absolute;
      top: 50%;
      right: 0;
      left: auto;
      transform: translateY(-50%);
      opacity: 0;
      transition: all 0.3s;

      span {
        position: absolute;
        top: -20px;
        right: 0;
        font-size: 14px;
        line-height: 16px;
        font-family: "Roboto", sans-serif;
      }
    }

    &.cancel {
      color: rgba(235, 75, 75, 0.4);
      cursor: pointer;

      span {
        color: rgba(235, 75, 75, 0.4);
      }
    }

    &.copy {
      color: rgba(230, 155, 255, 0.4);
      cursor: pointer;

      span {
        color: rgba(230, 155, 255, 0.4);
        opacity: 0;
      }

      &:hover {
        color: rgba(230, 155, 255, 0.24);

        span {
          color: rgba(230, 155, 255, 0.24);
          opacity: 1;
        }
      }
    }
  }

  &.error {
    .icon.cancel {
      opacity: 1;
    }
  }

  &.check {
    .icon.check {
      opacity: 1;
    }
  }

  &.input-copy {
    font-size: 24px;
    line-height: 24px;
    color: #3B1674;

    &:before {
      display: none;
    }

    .fake-input {
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: rgba(230, 155, 255, 0.8);
      text-align: left;
      text-transform: none;
      white-space: nowrap;
      overflow-x: clip;
      text-overflow: ellipsis;
      width: calc(100% - 30px);
      text-shadow: none;
      cursor: pointer;
    }

    .copy {
      text-shadow: none;

      span {
        opacity: 1;
        transition: all .3s;
      }
    }

    &:hover {
      .fake-input {
        color: #D32CE6;
        text-shadow: 0px 0px 24px #D32CE6;
      }

      .copy {
        color: #E69BFF;

        span {
          color: #E69BFF;
        }
      }
    }
  }
}